import "../scss/Strategy.scss"
import ReactMarkdown from 'react-markdown';
import {useEffect, useState} from "react";
import {VideoLinkCard} from "../../LinkCards/js/VideoLinkCard";

export const Strategy = ({props}) => {

    const {strategies} = props
    const [strategyID, setStrategyID] = useState(null)
    const [strategySummary, setStrategySummary] = useState("No strategy summary found for this difficulty.")

    const handleDifficultyChange = (event) => {
        setStrategyID(event.target.value)
    }

    useEffect(() => {
        setStrategyID(strategies[0]?._id)
    }, [strategies])

    useEffect(() => {
        const strategy = strategies.find((strategy) => {
            return strategy._id === strategyID
        })?.strategy_summary

        setStrategySummary(strategy ? strategy : "# No strategy summary found for this difficulty. \n # Please check the video links.")
    }, [strategyID])

    return (
        <div className={"strategy-content-box cp-regular"}>
            <div className={"difficulty-dropdown"}>
                <label htmlFor={"strategy-difficulty"}>Boss Difficulty</label>
                <select onChange={handleDifficultyChange} id={"strategy-difficulty"}>
                    {strategies.map((strategy) => {
                        return (
                            <option key={strategy._id} value={strategy._id}>{strategy.difficulty}</option>
                        )
                    })}
                </select>
            </div>
            <div className={"summary-wrapper"}>
                <div className={"side-bar"}>
                    <div className={"side-bar-content"}>
                        <div className={"side-bar-title cp-bold"}>
                            Guide Videos
                        </div>
                        <div className={"side-bar-items-container"}>
                            {strategies.find((strategy) => {
                                return strategy._id === strategyID
                            })?.contributing_videos.map((video) => {
                                if (video.do_not_use) {
                                    return null
                                }
                                return (
                                    <a href={video.video_url} key={video.video_id}>
                                        <VideoLinkCard key={video.video_id} props={video}/>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={"summary-container"}>
                    <ReactMarkdown>
                        {
                            strategySummary
                        }
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    )
}
