import "../scss/DiscordApp.scss"
import {useEffect, useState} from "react";

export const DiscordApp = () => {

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        // Function to update the height
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 120);
        };

        // Set the initial height
        handleResize();

        // Add event listener to update the height on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount

    return (
        <div className={"page-container"} id={"page-container"} style={{
            height: `${windowHeight}px`, // Adjust the element's height
            maxHeight: `${windowHeight}px`,
        }}>
            <div className={"center-content-container  cp-regular"}>
                <div className={"title-text cp-bold"}>
                    Discord Bot App
                </div>
                <div className={"description-text"}>
                    <p>
                        We're also developing a BossFights.io companion Discord app.
                    </p>
                    <p>
                        With this app we're hoping to bring you all the available boss fight strategies, straight to
                        your Discord server.
                    </p>
                    <p>
                        The current app is still in development:
                        <span className={"in-page-link"}>
                            <a className={"text-hyperlink"}
                               href={"https://discord.com/oauth2/authorize?client_id=1246232279915692125"}>Check It Out!</a>
                        </span>
                    </p>
                    <div className={"image-container"}>
                        <img className={"bot-app-preview"} src={"/images/bot_app_screenshot.png"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
