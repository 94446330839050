import {Component} from "react";
import {NavLink} from "react-router-dom";
import {IoClose, IoMenu} from "react-icons/io5";
import constants from "../../../Base/Constants/Constants.js"

import "../scss/Navbar.scss"

export default class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: false
        }
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            showMenu: !prevState.showMenu
        }));
    }

    closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
            this.setState(() => ({
                showMenu: false
            }))
        }
    }


    render() {
        return (
            <header className="header">
                <nav className="nav container cp-bold">
                    <div className="nav-logo-and-title-container">
                        <NavLink
                            to="/"
                            className="nav__logo cp-bold"
                            onClick={this.closeMenuOnMobile}
                        >
                            {constants.COMPANY_NAME}
                        </NavLink>
                    </div>
                    <div className={`nav__menu ${this.state.showMenu ? "show-menu" : ""}`} id="nav-menu">
                        <ul className="nav__list">
                            <li className="nav__item">
                                <NavLink
                                    to="/"
                                    className="nav__link"
                                    onClick={this.closeMenuOnMobile}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    to="/bosses"
                                    className="nav__link"
                                    onClick={this.closeMenuOnMobile}
                                >
                                    Bosses
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    to="/about-us"
                                    className="nav__link"
                                    onClick={this.closeMenuOnMobile}
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    to="/discord-app"
                                    className="nav__link"
                                    onClick={this.closeMenuOnMobile}
                                >
                                    Discord App
                                </NavLink>
                            </li>
                            {!this.state.showMenu ? (<li className="nav__item">
                                <a
                                    href="https://www.facebook.com/bossfights.io"
                                    className="nav__link"
                                    onClick={this.closeMenuOnMobile}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-square-facebook facebook-icon"></i>
                                </a>
                            </li>) : null}
                        </ul>
                        {this.state.showMenu ? (
                            <div className={"socials-container"}>
                            <a
                                href="https://www.facebook.com/bossfights.io"
                                className="nav__link"
                                onClick={this.closeMenuOnMobile}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa-brands fa-square-facebook facebook-icon"></i>
                            </a>
                        </div>
                        ) : null}

                        <div
                            className="nav__close"
                            id="nav-close"
                            onClick={this.toggleMenu}
                        >
                            <IoClose/>
                        </div>
                    </div>
                    <div
                        className="nav__toggle"
                        id="nav-toggle"
                        onClick={this.toggleMenu}
                    >
                    <IoMenu/>
                    </div>
                </nav>
            </header>
        )
    }
}