import './App.scss';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Home} from "./Pages/js/Home";
import Navbar from "./Components/Navbar/js/Navbar";
import {AboutUs} from "./Pages/js/AboutUs";
import {DiscordApp} from "./Pages/js/DiscordApp";
import {Bosses} from "./Pages/js/Bosses";
import {useWindowWidth} from "./Utils/useWindowWidth";

// Component to render based on viewport width
const ResponsiveComponent = ({children}) => {
    const windowWidth = useWindowWidth();

    // Render the alternative component if the window width is less than 900px
    return windowWidth < 900 ? (
        <div className={"no-mobile-support cp-bold"}>
            <p>
                We're sorry.
            </p>
            <p>
                We don't support mobile devices... YET!
            </p>
            <p>
                Please check us out on a full resolution device.
            </p>

        </div>
    ) : (
        children
    );
};

function App() {
    return (
        <Router>
            <Navbar/>
            <main className="main-content">
                <div className="depressed-style">
                    <ResponsiveComponent>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/bosses" element={<Bosses/>}/>
                            <Route path="/about-us" element={<AboutUs/>}/>
                            <Route path="/discord-app" element={<DiscordApp/>}/>
                        </Routes>
                    </ResponsiveComponent>
                </div>
            </main>
        </Router>
    );
}

export default App;
