import '../scss/Bosses.scss'
import {useEffect, useState} from "react";
import {GameLinkCard} from "../../Components/LinkCards/js/GameLinkCard";
import {BossLinkCard} from "../../Components/LinkCards/js/BossLinkCard";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import axios from 'axios';
import constants from "../../Base/Constants/Constants.js";
import {Strategy} from "../../Components/Strategy/js/Strategy";

export const Bosses = () => {

    const [selectedGame, setSelectedGame] = useState('')
    const [selectedBoss, setSelectedBoss] = useState('')
    const [gameData, setGameData] = useState([])
    const [bossData, setBossData] = useState([])
    const [boss, setBoss] = useState({})
    const [strategies, setStrategies] = useState([])
    const [loadingGameData, setLoadingGameData] = useState(true)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    let gameId = queryParams.get('gameID') || null;
    let bossId = queryParams.get('bossID') || null;
    let difficulty = queryParams.get('difficulty') || null;
    const base_url = constants.BACKEND_URL

    const handleSelectedGameChange = (event) => {
        console.log("SelectedGameChange")
        if (event.target.value) {
            navigate(`/bosses?gameID=${event.target.value}`);
        } else {
            setSelectedGame('')
            navigate(`/bosses`);
        }
    }

    const handleSelectedBossChange = (event) => {
        console.log("SelectedBossChange")
        if (event.target.value) {
            navigate(`/bosses?gameID=${gameId}&bossID=${event.target.value}`);
        } else {
            setSelectedGame('')
            navigate(`/bosses?gameID=${gameId}`);
        }
    }

    const handleSelectedDifficultyChange = (event) => {
        // stuff
    }

    useEffect(() => {
        // Function to update the height
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 120);
        };

        // Set the initial height
        handleResize();

        // Add event listener to update the height on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount


    useEffect(() => {
        const fetchBoss = async () => {
            try {
                const url = `${base_url}/api/boss/${selectedBoss}/`
                const response = await axios.get(url);
                setBoss(response.data)
                setStrategies(response.data.strategies)
            } catch (error) {
                // FUCK
            }
        }

        if (selectedBoss) {
            fetchBoss()
        }

    }, [selectedBoss])

    useEffect(() => {
        const fetchGameData = async () => {
            try {

                const url = `${base_url}/api/game/?list=true`
                const response = await axios.get(url);
                setTimeout(() => {
                    setGameData(response.data);
                }, 1000)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setTimeout(() => {
                    setLoadingGameData(false);
                }, 2000)

            }
        };

        fetchGameData();
    }, []);

    // Update selectedGame based on gameId from URL
    useEffect(() => {
        const fetchBossData = async () => {
            try {
                const base_url = constants.BACKEND_URL
                const url = `${base_url}/api/boss/?game_id=${gameId}`
                const response = await axios.get(url);
                setBossData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (gameId) {
            fetchBossData();
        }
    }, [gameId, selectedGame]);

    // Update selectedGame based on gameId from URL
    useEffect(() => {
        if (gameId !== selectedGame) {
            setSelectedGame(gameId);
        }
    }, [gameId, selectedGame]);

    // Update selectedBoss based on bossId from URL
    useEffect(() => {
        if (bossId !== selectedBoss) {
            setSelectedBoss(bossId);
        }
    }, [bossId, selectedBoss]);

    return (
        <div className={"page-container"} style={{
            height: `${windowHeight}px`, // Adjust the element's height
            maxHeight: `${windowHeight}px`,
        }}>
            <div className={"boss-content-container"}>
                {gameData.length > 0 ?
                    (<div>
                        <div className={"boss-content-box cp-regular"}>
                            <div className={"boss-detail-select-container"}>
                                <label htmlFor={"gameSelect"}>Select Game:</label>
                                {loadingGameData ? (
                                    <select value={''} id={"gameSelect"} disabled={true}>
                                        <option value={''} defaultValue={true}>Loading Game Data...</option>
                                    </select>
                                ) : (
                                    <select value={gameId || ''} onChange={handleSelectedGameChange} id={"gameSelect"}>
                                        <option value={''} defaultValue={true}>Please select a game...</option>
                                        {gameData?.map((game) => (
                                            <option key={game.id} value={game.id}>{game.display_name}</option>
                                        ))}
                                    </select>
                                )}

                            </div>
                            {selectedGame === null ? null : (
                                <div className={"boss-detail-select-container"}>
                                    <label htmlFor={"gameSelect"}>Select Boss:</label>
                                    <select value={bossId || ''} onChange={handleSelectedBossChange} id={"bossSelect"}>
                                        <option value={""}>Please select a boss...</option>
                                        {bossData?.map((boss) => (
                                            <option key={boss._id} value={boss._id}>{boss.display_name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        {
                            bossId ? null : (
                                <div className={"current-selection-options-grid"}>
                                    {loadingGameData ? null : (selectedGame === null ? gameData?.map((game) => {
                                        return (
                                            <div className={"grid-item"} key={game.id}>
                                                <NavLink to={`/bosses?gameID=${game.id}`}>
                                                    <GameLinkCard key={game.id} props={game}></GameLinkCard>
                                                </NavLink>
                                            </div>
                                        )
                                    }) : null)}
                                    {
                                        selectedGame !== '' && gameId !== null && bossId === null ?
                                            bossData?.map((boss) => {
                                                const game = gameData.find((game) => {
                                                    return game.id === selectedGame
                                                })
                                                boss.game_icon_url = game?.image_url
                                                return (
                                                    <div className={"grid-item"} key={boss._id}>
                                                        <NavLink
                                                            to={`/bosses?gameID=${selectedGame}&bossID=${boss._id}`}>
                                                            <BossLinkCard key={boss._id} props={boss}></BossLinkCard>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                            : null
                                    }
                                </div>
                            )
                        }
                        {
                            bossId ? (
                                <Strategy props={{strategies: strategies}}/>
                            ) : null
                        }
                    </div>)
                    : (loadingGameData ? <div className={"loading-box cp-bold"}><div className={"depressed-style"}><div className={"text"}>LOADING</div> <div className="throbber"></div></div></div> :
                        <div className={"boss-content-box-no-game-data cp-regular"}>
                            <div className={"we-aint-found-shit"}>
                                <div className={"description-text"}>
                                    We are having trouble connecting to the interwebs right now.
                                </div>
                                <div className={"oh-no-text my-fonts-new-amsterdam-regular"}>
                                    What?! Oh no!
                                </div>
                            </div>
                        </div>)}
            </div>
        </div>
    )
}
