import "../scss/GameLinkCard.scss"

export const GameLinkCard = ({props}) => {
    const {display_name, id, image_url, game_name_slug} = props

    return (
        <div className={"game-link-card"} id={id}>
            <img className={"game-image"} src={image_url} alt={game_name_slug}/>
            <div className={"game-name cp-bold"}>
                {display_name}
            </div>
        </div>
    )
}
