import "../scss/Home.scss"
import {BossLinkCard} from "../../Components/LinkCards/js/BossLinkCard";
import {useEffect, useState} from "react";
import axios from "axios";
import constants from "../../Base/Constants/Constants";
import {NavLink} from "react-router-dom";

export const Home = () => {
    // the threshold for when the elements in this page are stacked vs horizontal. (mobile)
    const stackThreshold = 821;
    const base_url = constants.BACKEND_URL
    const [isBelowThreshold, setIsBelowThreshold] = useState(window.innerWidth < stackThreshold)
    const [latestBossesAdded, setLatestBossesAdded] = useState([])
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const checkWindowSize = () => {
        setIsBelowThreshold(window.innerWidth < stackThreshold)
    }

    useEffect(() => {
        window.addEventListener('resize', checkWindowSize)

        return (() => {
            window.removeEventListener('resize', checkWindowSize)
        })
    })

    useEffect(() => {
        // Function to update the height
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 120);
        };

        // Set the initial height
        handleResize();

        // Add event listener to update the height on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount

    useEffect(() => {
        const getLatestBosses = async () => {
            const url = `${base_url}/api/boss/latest/`
            try {
                const response = await axios.get(url);
                if (response.data?.bosses && response.data?.bosses.length > 0) {
                    setLatestBossesAdded(response.data.bosses)
                }
            } catch (error){
                // oh noes!
                console.error('Error fetching data:', error);
            }
        }
        getLatestBosses()
    }, [])

    const sideBarContent = () => {
        // Only show side bar if API returns latest bosses. 
        return (
            latestBossesAdded.length > 0
                ? <div className={"side-bar"}>
                    <div className={"side-bar-content"}>
                        <div className={"side-bar-title cp-bold newest-additions"}>
                            Newest Additions
                        </div>
                        <div className={"side-bar-items-container"}>
                            {latestBossesAdded.map((bossData) => {
                                return (
                                    // TODO: bossData.game_id._id needs to be structured better. This doesn't make sense.
                                    // make it bossData.game._id
                                    <NavLink to={`/bosses?gameID=${bossData.game_id._id}&bossID=${bossData._id}`}>
                                        <BossLinkCard key={bossData._id} props={bossData}/>
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                </div>
                : null
        )
    }

    return (
        <div className={"page-container"}  style={{
            height: `${windowHeight}px`, // Adjust the element's height
            maxHeight: `${windowHeight}px`,
        }}>
            {!isBelowThreshold ? sideBarContent() : null}
            <div className={"center-content-container cp-regular"}>
                <img className={"bossfights-logo"} src={"/images/bossfights_logo.jpg"} alt={""}/>
                <div className={"title-text cp-bold"}>
                    Welcome to BossFights.io!
                </div>
                <div className={"description-text"}>
                    <p>
                        Here at BossFights.io, we're hoping to bring you a one-stop-shop for all your boss fight
                        strategy needs.
                    </p>
                    <p>
                        This is a side-project and a labor of love of a single developer who loves to game and make
                        things.
                        So please bear with us as we get this thing going.
                    </p>
                    <p>
                        We're just getting started, so please check back soon for more updates!
                    </p>
                </div>
            </div>
            {isBelowThreshold ? sideBarContent() : null}
        </div>
    )
}
