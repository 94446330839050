import "../scss/VideoLinkCard.scss"

export const VideoLinkCard = ({props}) => {
    const { video_id, author_name, video_url, video_thumbnail_image_url } = props

    return (
        <div className={"video-link-card"} id={video_id}>
            <img className={"video-image"} src={video_thumbnail_image_url} alt={author_name}/>
            <div className={"video-name cp-bold"}>
                {author_name}
            </div>
        </div>
    )
}
