import "../scss/BossLinkCard.scss"

export const BossLinkCard = ({props}) => {
    const {display_name_short, image_url, game_icon_url } = props
    return (
        <div className={"boss-link-card"}>
            <img className={"boss-image"} src={image_url} alt={display_name_short}/>
            <div className={"game-icon-container"}>
                <img className={"game-icon"} src={game_icon_url} alt={""}/>
            </div>
            <div className={"boss-name cp-bold"}>
                {display_name_short}
            </div>
        </div>
    )
}
