import "../scss/AboutUs.scss"
import {useEffect, useState} from "react";

export const AboutUs = () => {

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        // Function to update the height
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 120);
        };

        // Set the initial height
        handleResize();

        // Add event listener to update the height on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount


    return (
        <div className={"page-container"}  id={"page-container"} style={{
            height: `${windowHeight}px`, // Adjust the element's height
            maxHeight: `${windowHeight}px`,
        }}>
            <div className={"center-content-container  cp-regular"}>
                <div className={"title-text cp-bold"}>
                    About BossFights.io
                </div>
                <div className={"description-text"}>
                    <p>
                        Here at BossFights.io, we're hoping to bring you a one-stop-shop for all your boss fight
                        strategy needs.
                    </p>
                    <p>
                        This is a side-project and a labor of love of a single developer who loves to game and make things.
                        So please bear with us as we get this thing going.
                    </p>
                    <p>
                        We're just getting started, so please check back soon for more updates!
                    </p>
                </div>
            </div>
        </div>
    )
}
